<template>
    <div class="participantsList">
        <div style="background-color:#C6C6C6;" class="text-left">
            <div style="color:white;font-size:24px;padding: 20px;background: #454545;">
                Participants
            </div>
        </div>
        <perfect-scrollbar>
            <div class="participants">
                <div v-for="(participant, idx) in this.participants" :key="idx">
                    <div class="row row_div_user_padding contact_white no-gutters"> 
                        <div class="col-2" style="flex: 0 0 10%;">
                            <div class="chat-pic">
                                <p v-if="participant.name" style="padding:4px;">{{ participant.name.substring(0, 1).toUpperCase() }}</p>
                            </div>
                        </div>
                        <div class="text-left col-6" style="white-space:nowrap;overflow:hidden;text-overflow:ellipsis;top:4px;">{{participant.name}}</div>
                    </div>
                </div>
            </div>
        </perfect-scrollbar>
    </div>
</template>

<script>
//import SpinnerComponent from "@/components/SpinnerComponent";

export default {
    //components: { SpinnerComponent },
    name: "ParticipantListComponent",
    props: {
        participants: {
            type: Array
        }
    },
    data() {
        return {
            //participants: [],
            showSpinner: true
        }
    },
    mounted() {
        //this.getList();
        console.log(this.participants);
    },
    methods: {
        getList() {
            //this.participants = [];
            //this.participants = window.expMain.getParticipant();
        }
    },
}
</script>

<style scoped>
#parties {
    width: 100%;
}

.participantsList {
    padding: 0px 15px 50px 15px;
    /*background: #B2B1AEB3;*/
    /*height: 80%;*/
    /*width: 100%;*/
    background: #454545;
    height: 97%;
    border-left: 3px solid #E4E4E4;
    overflow: hidden;
}

.participants {
    height: 100%;
}

.colstable {
    padding-left: unset;
    padding-right: unset;
}

.contact_white {
    background: #FFFFFF;
}

.contact_grey {
    background: #E4E4E4;
}

.row_div_user_padding {
    padding: 10px 0px 10px 20px;
}

.chat-pic {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-right: 15px;
    background-color: #FF6C11;
    color: white;
    margin-top: 2px;
}

.list {
    height: 100%;
}

.ps {
    /*width:100%;*/
    /*max-width: 1120px;*/
    /*height: 50%;*/
    /*overflow: auto;*/
    /*overflow-x: hidden;*/
    width: 100%;
    max-width: 1120px;
}

.ps__rail-y:hover > .ps__thumb-y, .ps__rail-y:focus > .ps__thumb-y, .ps__rail-y.ps--clicking .ps__thumb-y {
    background-color: grey !important;
}

.spinner {
    top: 40%;
}
</style>

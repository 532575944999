<template>
    <div class="alert-box" :class="{ success : type == 'success', error : type == 'error', warning : type == 'warning' }">
        <p v-html="text"></p>
    </div>
</template>

<script>
    export default {
        name: "AlertComponent",
        props: ["type","text"],
        data() {
            return {
                selectedItem: null
            };
        },
        methods: {
            onItemSelected(item) {
                this.selectedItem = item;
            }
        }
    };
</script>

<style scoped>
    .alert-box {
        position: absolute;
        top: 0;
        z-index: 9999;
        left: 50%;
        transform: translateX(-50%);
        max-width: 700px;
        width: 22%;
        border: 2px solid #FFFFFF;
        border-radius: 0 0 24px 24px;
        color: #FFFFFF;
        text-align: center;
        font-family: 'Roboto',sans-serif;
        font-size: 20px;
        padding: 20px 10px 10px;
    }

    .success {
        background: #6DA800;
    }

    .warning {
        background: #FFBF08;
    }

    .error {
        background: #800001;
    }
</style>

import Vue from "vue";
import Vuex from "vuex";
import App from "./App.vue";
import router from "./router";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import { InlineSvgPlugin } from "vue-inline-svg";

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import LoadScript from "vue-plugin-load-script";
import axios from "axios";
import store from "./store";
import PerfectScrollbar from "vue2-perfect-scrollbar";
import "vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css";

import "@/components/AlertComponent";
import "@/components/SpinnerComponent";
import "@/components/GalleryComponent";
import "@/components/AnnotationComponent";

window.$ = require("jquery");
window.jQuery = require("jquery");
Vue.prototype.$http = axios;

Vue.use(Vuex);
Vue.use(LoadScript);
// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);
Vue.use(InlineSvgPlugin);
Vue.use(PerfectScrollbar);

Vue.config.productionTip = false;

fetch('/config/' + window.location.hostname + ".json")
    .then((resp) => {
        resp.json().then((config) => {
          console.log('config', config);
            Vue.prototype.$config = config;
            new Vue({
                router,
                store,
                render: (h) => h(App),
            }).$mount("#app");
        });
    })
    .catch((e) => console.log(e));

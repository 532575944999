<template>
    <div class="spinner">
        <img src="../assets/spinner.gif" alt="spinner" class="spinnerImg">
    </div>
</template>

<script>
    export default {
        name: "SpinnerComponent",
    };
</script>

<style scoped>
    .spinner {
        position: absolute;
        transform: translate(-50%, -50%);
        top: 50%;
        left: 50%;
        z-index: 1;
    }
    .spinnerImg {
      width: 100% !important;
      height: 100% !important;
    }
</style>

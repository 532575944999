import Vue from "vue";
import Vuex from "vuex";
import VuexPersist from 'vuex-persist';

Vue.use(Vuex);

const vuexPersist = new VuexPersist({
    key: 'media_server',
    storage: window.localStorage
})

export default new Vuex.Store({
    state: {
        media_server: {
            url: '',
            access_token: '',
        },
        user: {
            'email': '',
            'password': '',
            'display_name': '',
        },
        meeting: {
            'id': '',
            'passcode': '',
            'attendees': [],
        },
        storage: {
            'available': '',
            'usage': '',
        }
    },
    getters: {
        getMediaServerUrl: state => state.media_server.url,
        getUserData: state => state.user,
        getUserStorage: state => state.storage,
        getUserToken: state => state.media_server.access_token,
        getMeetingData: state => state.meeting,
        isLogged: (state) => {
            return !!(state.user.email && state.user.password);
        },
        inMeeting: (state) => {
            return state.meeting.id;
        }
    },
    mutations: {
        changeMediaServerUrl(state, payload) {
            state.media_server.url = payload;
        },
        userAccessToken(state, payload) {
            state.media_server.access_token = payload;
        },
        changeUser(state, payload) {
            state.user = payload;
        },
        changeStorage(state, payload) {
            state.storage = payload;
        },
        changeMeeting(state, payload) {
            state.meeting = payload;
        },
        resetUserSessionData(state) {
            state.user.email = '';
            state.user.password = '';
            state.user.display_name = '';
            state.media_server.access_token = '';
        },
        resetMeetingData(state) {
            state.meeting.id = '';
            state.meeting.passcode = '';
        }
    },
    actions: {
        changeMediaServerUrl(context, payload) {
            context.commit("changeMediaServerUrl", payload);
        },
        userAccessToken(context, payload) {
            context.commit("userAccessToken", payload);
        },
        changeUser(context, payload) {
            context.commit("changeUser", payload);
        },
        changeStorage(context, payload) {
            context.commit("changeStorage", payload);
        },
        changeMeeting(context, payload) {
            context.commit("changeMeeting", payload);
        },
    },
    plugins: [vuexPersist.plugin]
});

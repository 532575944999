<template>
	<div class="home">
        Home
	</div>
</template>

<script>
	module.exports = {
		data: function() {
			return {

			};
		}
	};
</script>
import Vue from 'vue'
import VueRouter from 'vue-router'
import Meeting from '../views/Meeting'
import Login from '../views/Login'
import Start from '../views/Start'
import Home from '../views/Home'
import store from '../store/index'
import VueSocketIO from 'vue-socket.io'
import SocketIO from 'socket.io-client'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        redirect: '/start'
    },
    {
        path: '/home',
        name: 'Home',
        component: Home
    },
    {
        path: '/login',
        name: 'Login',
        component: Login
    },
    {
        path: '/start/:meeting_id?/:meeting_passcode?',
        name: 'Start',
        component: Start
    },
    {
        path: '/meeting/:meeting_id?/:meeting_passcode?',
        name: 'Meeting',
        component: Meeting,
        beforeEnter: (to, from, next) => {

            if (to.params.meeting_id != undefined) {
                var id = to.params.meeting_id;
                var passcode = to.params.meeting_passcode;
                store.dispatch('changeMeeting', {id, passcode});
            }

            if (store.getters.isLogged || (store.getters.getUserData.display_name !== '' && to.params.meeting_id != undefined)) {
                const options = {query: {token: process.env.VUE_APP_TEAMS_BOT_SOCKET_TOKEN}}

                Vue.use(new VueSocketIO({
                    debug: false,
                    connection: SocketIO(process.env.VUE_APP_TEAMS_BOT_SOCKET_URL, options)
                }))

                next();
            } else {
                if (to.params.meeting_id != undefined) {
                    next('/start/' + to.params.meeting_id + '/' + to.params.meeting_passcode);
                } else {
                    next('/start');
                }
            }

            /*if ((store.getters.getUserData.display_name == '' || to.params.meeting_id == undefined) && !store.getters.isLogged) {
                next('/start');
            } else {
                if (store.getters.isLogged) {
                    if (to.params.meeting_id != undefined) {
                        var id = to.params.meeting_id;
                        var passcode = '';
                        store.dispatch('changeMeeting', {id, passcode});
                    }
                    const options = {query: {token: process.env.VUE_APP_TEAMS_BOT_SOCKET_TOKEN}} // TODO: move to env - pay attention to double $ escaping

                    Vue.use(new VueSocketIO({
                        debug: false,
                        connection: SocketIO(process.env.VUE_APP_TEAMS_BOT_SOCKET_URL, options)
                    }))
                }

                next();
            }*/
        }
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})


export default router

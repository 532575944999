<template>
	<b-container fluid class="background">
        <div class="login-page-container">
            <div class="fre-logo-container">
                <img class="fre-logo" src="../assets/fre_logo.svg" alt="fusion remote logo">
                <img class="line" src="../assets/line.png" alt="horizontal line">
                <img src="../assets/expertview.png" alt="expert view">
            </div>
            <div class="login-container">
                <h2 class="header-paragraph">LOG IN</h2>
                <div class="input-container">
                    <label for="email-username">Email address or username</label>
                    <input id="email-username" type="text" placeholder="Enter here" v-model="email">
                </div>
                <div class="input-container pwd-input-container">
                    <label for="password">Password</label>
                    <input id="password" placeholder="Your password" :type="passwordFieldType" v-model="password">
                    <img class="show-pwd" src="../assets/eye.svg" alt="show pwd" @click="switchVisibility">
                </div>
                <div class="reset-pwd">
                    <a href="#">Forgot your password?</a>
                </div>
                <div class="cta">
                    <button @click="domainLookup()">LOG IN</button>
                </div>
            </div>
            <div class="or">
                <img src="../assets/or.png" alt="or">
            </div>
            <div class="join-guest-container">
                <h2 class="header-paragraph">JOIN AS GUEST</h2>
                <div class="input-container">
                    <label for="name">Email your name</label>
                    <input id="name" type="text" placeholder="Your name">
                </div>
                <div class="cta cta-guest">
                    <button>JOIN AS GUEST</button>
                </div>
            </div>
            <div class="j-logo-bottom">
                <img src="../assets/j-logo-full.svg" alt="jre logo">
            </div>
        </div>
    </b-container>
</template>

<style scoped>
    .background {
        height: 100vh;
        background: url("../assets/oil_field_bg.png") no-repeat center center fixed;
        background-size: cover;
    }

    .login-page-container {
        max-width: 600px;
        height: 100vh;
        display: inline-block;
        text-align: start;
        padding: 40px 100px;
        background: #FFFFFF;
    }

    .fre-logo-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 100px;
    }

    .fre-logo {
        margin-bottom: 10px;
    }

    .line {
        margin-bottom: 7px;
    }

    .header-paragraph {
        color: #131523;
        font: normal normal 900 24px/20px Roboto;
        letter-spacing: 0.24px;
        margin: 0 0 15px;
    }

    .input-container {
        display: flex;
        flex-direction: column;
        margin-bottom: 18px;
    }

    input {
        padding-left: 15px;
    }

    input:focus {
        outline: none;
    }

    .input-container label {
        margin: 0 0 0 15px;
        font: normal normal bold 12px/20px Roboto;
        letter-spacing: 0.12px;
        color: #131523;
    }

    .input-container input {
        margin-top: 0;
        margin-bottom: 0;
        height: 32px;
        font: normal normal bold 12px/20px Roboto;
        letter-spacing: 0.12px;
        color: #131523;
        /*border: 1px solid #646464;*/
        /*border-radius: 12px;*/
    }

    ::placeholder {
        font-weight: 400;
        color: #808080;
    }

    .reset-pwd {
        margin-left: 15px;
    }

    .reset-pwd a {
        text-decoration: underline;
        color: #131523;
        font-size: 12px;
    }

    .pwd-input-container {
        position: relative;
    }

    .show-pwd {
        cursor: pointer;
        position: absolute;
        right: -35px;
        top: 28px;
        width: 24px;
        height: 15px;
    }
    .cta {
        display: flex;
        justify-content: flex-end;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .cta button {
        background: rgb(0,162,215);
        border-radius: 12px;
        border: 0;
        font-size: 14px;
        color: #FFFFFF;
        font-weight: 600;
        padding: 8px 73px;
    }

    .or img {
        margin-top: 15px;
        width: 100%;
    }

    /*  guest  */

    .join-guest-container {
        margin-top: 25px;
    }

    .cta-guest button {
        padding: 8px 43px;
    }

    .j-logo-bottom {
        display: flex;
        justify-content: center;
        margin-top: 50px;
        padding-bottom: 25px;
    }

    .j-logo-bottom img {
        width: 110px;
    }
</style>

<script>
export default {
    data: function () {
        return {
            nameCompany: "YourCompanyName",
            email: '',
            password: '',
            passwordFieldType: 'password'
        };
    },
    methods: {
        checkActive: function () {
            this.isActive = true;
            this.status = this.isActive ? 'Active' : 'Not Active';
        },
        switchVisibility: function () {
            this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password';
        },
        domainLookup: function () {
            this.$store.dispatch("changeUser", {email: this.email, password: this.password});
            let userData = this.$store.getters.getUserData;

            let inputEmail = this.validateEmail(this.email);
            if (inputEmail) {
                console.log(inputEmail);
                let domain = this.email.substring(this.email.lastIndexOf("@") +1);

                console.log(domain);
                let bodyFormData = new FormData();
                bodyFormData.append('serial', 'RXC1234567890');

                this.$http
                    .get(`${process.env.VUE_APP_DOMAIN_LOOKUP}?domain=${domain}`, bodyFormData, {headers: {'Content-Type': 'multipart/form-data' }})
                    .then((response) => {
                        // console.log(response.data);
                        let apiStoreUrl = response.data.tenant.organization.storage_service_url;
                        if (apiStoreUrl !== this.$store.getters.getMediaServerUrl) {
                            this.$store.dispatch("changeMediaServerUrl", apiStoreUrl);
                        }

                        // post user auth media server
                        this.$http
                            .post(`${process.env.VUE_APP_MEDIA_SERVER}`, {username: userData.email, password: userData.password})
                            .then((response) => {
                                console.log(response.data);
                            })
                            .catch((err) => console.log(err));

                    })
                    .catch((err) => console.log(err))
            } else {
                console.log('not email');
            }
        },
        validateEmail: function(email) {
            const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(String(email).toLowerCase());
        }
    },
    mounted () {
        // this.domainLookup();
        // console.log(this.$store.getters.getMediaServerUrl);

        let bodyFormData = new FormData();
        bodyFormData.append('serial', 'RXC1234567890');

        this.$http
            .post(`${process.env.VUE_APP_API_AUTH}`, bodyFormData, {headers: {'Content-Type': 'multipart/form-data' }})
            .then((response) => {
                // console.log(response.data);
                this.$store.dispatch("changeMediaServerUrl", response.data.storage_service_url);
                // console.log(this.$store.getters.getMediaServerUrl);
            })
            .catch((err) => console.log(err));
    }
}
</script>
